<template>
  <b-modal
    id="edit-master-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    :title="`Редактирование мастера ${master.first_name} ${master.last_name}`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-3">
        <div>
          <b-form-group
            label="Имя"
            label-for="first_name"
          >
            <b-form-input
              id="first_name"
              v-model="editMaster.first_name"
              v-validate="'required'"
              name="first_name"
            />
            <small
              v-show="errors.has('first_name')"
              class="text-danger"
            >{{ errors.first('first_name') }}</small>
          </b-form-group>
          <b-form-group
            label="Фамилия"
            label-for="last_name"
          >
            <b-form-input
              id="last_name"
              v-model="editMaster.last_name"
              v-validate="'required'"
              name="last_name"
            />
            <small
              v-show="errors.has('last_name')"
              class="text-danger"
            >{{ errors.first('last_name') }}</small>
          </b-form-group>
          <b-form-group
            label="Фамилия"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="editMaster.phone"
              v-mask="'+7 (###) ###-##-##'"
              v-validate="'required'"
              name="phone"
            />
            <small
              v-show="errors.has('phone')"
              class="text-danger"
            >{{ errors.first('phone') }}</small>
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Master from '@/api/http/models/master/Master'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },
  mixins: [mixinErrorsParse],
  props: {
    master: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
    title: {
      type: String,
      default: 'Добавление акции',
    },
  },
  data() {
    return {
      visible: false,
      editMaster: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  created() {
    this.editMaster = this.master
    this.visible = true
  },
  methods: {

    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Master.update(this.master.id, this.editMaster)

        if (response.status === 'success') {
          this.$emit('updated', this.editMaster)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Мастер обновлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('edit-master-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при обновлении акции',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }

        this.$nextTick(() => {
          this.$bvModal.hide('page-edit-popup')
        })
      }
    },
  },
}
</script>
