<template>
  <b-modal
    id="delete-master-popup"
    :visible="visible"
    size="md"
    ok-title="Удалить"
    cancel-title="Отменить"
    ok-variant="danger"
    :title="`Удалить мастера ${master.first_name} ${master.last_name}?`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Master from '@/api/http/models/master/Master'

export default {
  props: {
    master: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      visible: false,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  created() {
    this.visible = true
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Master.delete(this.master.id)

        if (response.status === 'success') {
          this.$emit('deleted', this.master)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Мастер «${this.master.first_name} ${this.master.last_name}» удален`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('delete-master-popup')
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Ошибка при удалении мастера «${this.master.first_name} ${this.master.last_name}»`,
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
