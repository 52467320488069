<template>
  <b-modal
    id="add-master-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    :title="title"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="mb-3">
        <div>
          <b-form-group
            label="Имя"
            label-for="first_name"
          >
            <b-form-input
              id="first_name"
              v-model="master.first_name"
              v-validate="'required'"
              name="first_name"
            />
            <small
              v-show="errors.has('first_name')"
              class="text-danger"
            >{{ errors.first('first_name') }}</small>
          </b-form-group>
          <b-form-group
            label="Фамилия"
            label-for="last_name"
          >
            <b-form-input
              id="last_name"
              v-model="master.last_name"
              v-validate="'required'"
              name="last_name"
            />
            <small
              v-show="errors.has('last_name')"
              class="text-danger"
            >{{ errors.first('last_name') }}</small>
          </b-form-group>
          <b-form-group
            label="Телефон"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="master.phone"
              v-mask="'+7 (###) ###-##-##'"
              v-validate="'required'"
              name="phone"
            />
            <small
              v-show="errors.has('phone')"
              class="text-danger"
            >{{ errors.first('phone') }}</small>
          </b-form-group>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Master from '@/api/http/models/master/Master'

export default {
  components: {
    BFormGroup,
    BFormInput,
  },

  mixins: [mixinErrorsParse],

  props: {
    title: {
      type: String,
      default: 'Добавление мастера',
    },
  },

  data() {
    return {
      visible: false,
      master: {
        first_name: '',
        last_name: '',
        phone: '',
      },
    }
  },

  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },

  async created() {
    this.visible = true
  },

  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Master.add(this.master)

        if (response.status === 'success') {
          this.$emit('added')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Мастер добавлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-master-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при добавлении мастера',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }

        this.$nextTick(() => {
          this.$bvModal.hide('page-edit-popup')
        })
      }
    },
  },
}
</script>
