<template>
  <div>
    <div class="accordion-label__content">
      <div class="row align-items-center w-100">
        <div class="col-8 col-lg-8">
          Имя
        </div>
        <div class="col-4 col-lg-4 text-right">
          Действия
        </div>
      </div>
    </div>
    <div
      class="accordion"
      role="tablist"
    >
      <div
        v-for="master in masters"
        :key="master.slug"
        class="accordion__item"
      >
        <div class="accordion-label accordion-label--disable-arrow">
          <div class="accordion-label__content">
            <div class="row align-items-center w-100">
              <div class="col-8">
                <div class="d-flex align-items-center">
                  <div class="content-text-wrap">
                    {{ master.first_name }} {{ master.last_name }}
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    @click.prevent="openEditPopup(master)"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="12"
                    />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    @click.prevent="openDeletePopup(master)"
                  >
                    <feather-icon
                      icon="Trash2Icon"
                      size="12"
                    />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <edit-master-popup
      v-if="showEditPopup"
      :key="`edit_${currentMasterId}`"
      :master="currentMaster"
      @updated="$emit('updated', currentMaster)"
      @hidden="hideEditPopup"
    />
    <delete-master-popup
      v-if="showDeletePopup"
      :key="`delete_${currentMasterId}`"
      :master="currentMaster"
      @deleted="$emit('deleted', currentMaster)"
      @hidden="hideDeletePopup"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { BButton } from 'bootstrap-vue'
import EditMasterPopup from './EditMasterPopup.vue'
import DeleteMasterPopup from './DeleteMasterPopup.vue'

export default {
  components: {
    BButton,
    EditMasterPopup,
    DeleteMasterPopup,
  },
  props: {
    masters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showEditPopup: false,
      showDeletePopup: false,
      currentMasterId: null,
      currentMaster: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  methods: {
    openEditPopup(master) {
      this.currentMasterId = master.id
      this.currentMaster = master
      this.showEditPopup = true
    },
    hideEditPopup() {
      this.currentMasterId = null
      this.currentMaster = null
      this.showEditPopup = false
    },
    openDeletePopup(master) {
      this.currentMasterId = master.id
      this.currentMaster = master
      this.showDeletePopup = true
    },
    hideDeletePopup() {
      this.currentMasterId = null
      this.currentMaster = null
      this.showDeletePopup = false
    },
  },
}
</script>
