<template>
  <b-card>
    <div class="row mb-2">
      <div class="col">
        <b-button
          class="d-flex align-items-center"
          variant="primary"
          @click.prevent="showAddPopup = true"
        >
          <feather-icon
            icon="PlusIcon"
            size="18"
            class="mr-1"
          />
          Добавить мастера
        </b-button>
      </div>
    </div>
    <div
      v-if="masters.length"
    >
      <masters-list
        :masters="masters"
        @updated="updateMasters"
        @deleted="deleteMaster"
      />
      <b-pagination
        v-if="total / per_page > 1"
        v-model="page"
        :total-rows="total"
        :per-page="per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        align="right"
        @input="onChangePage"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <div v-else>
      Нет мастеров
    </div>
    <add-master-popup
      v-if="showAddPopup"
      @added="addMaster"
      @hidden="showAddPopup = false"
    />
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard, BButton, BPagination,
} from 'bootstrap-vue'

import Master from '@/api/http/models/master/Master'

import AddMasterPopup from './AddMasterPopup.vue'
import MastersList from './MastersList.vue'

export default {
  components: {
    BCard,
    BButton,
    BPagination,
    MastersList,
    AddMasterPopup,
  },
  data() {
    return {
      showAddPopup: false,
      masters: [],
      is_published: false,
      page: 1,
      per_page: 1,
      total: 1,
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    this.page = this.$route.query.page || 1
    this.is_published = this.$route.query.is_published || 0

    await this.fetchMasters()
  },
  methods: {
    async fetchMasters() {
      const query = {}

      if (this.page) {
        query.page = this.page
      }

      if (this.is_published) {
        query.is_published = 1
      }

      const response = await Master.getAll(query)

      this.masters = response.data || []

      // this.per_page = response.meta.per_page || 1

      // this.total = response.meta.total || 1
    },
    onChangePage() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          page: this.page,
        },
      })

      this.fetchMasters()
    },
    async addMaster() {
      await this.fetchMasters()
    },
    async updateMasters(master) {
      const index = this.masters.findIndex(cur => cur.id === master.id)

      if (index !== -1) {
        this.masters.splice(index, 1, master)
      }

      await this.fetchMasters()
    },
    async deleteMaster(master) {
      const index = this.masters.findIndex(cur => cur.id === master.id)

      if (index !== -1) {
        this.masters.splice(index, 1)
      }

      await this.fetchMasters()
    },
  },
}
</script>
